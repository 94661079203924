import {css} from 'styled-components';

export default css`
  /* #rev-GlobalSpinner {
  } */

  #rev-ProductListPage .rev-FilterMenuL2,
  #rev-ProductDetailPage .rev-FilterMenuL2 {
    position: sticky;
    top: 72px;
    margin: 0px 10px;

    & > .header {
      padding: 23px 0px;
      font-size: 1.25rem;

      & > .label {
        display: none;
      }

      &::before {
        content: '房型列表';
        flex: 1;
        font-size: 1.3rem;
      }
    }

    & > .L1 {
      background-color: transparent;
      border-radius: 0px;
      padding: 0px;
      margin-bottom: 0px;
      box-shadow: none;

      & > .header {
        padding: 10px 0;
        border-top: 1px solid #d0d0d0;
        font-size: 1rem;
        color: #000000;
      }

      & > .L1-items {
        border-top: 1px solid #d0d0d0;
        padding-left: 15px;
        overflow: hidden;

        & > .L2 {
          padding: 5px 10px;
        }

        & > .L2 > .header {
          font-size: 0.95rem;
          color: #6f6f6f;
        }

        & > .L2.selected > .header {
          font-size: 0.95rem;
          color: var(--primaryColor);
        }
      }

      &:last-child {
        border-bottom: 1px solid #d0d0d0;
      }
    }
  }

  #rev-ProductDetailPage .variants,
  .add-cart-button {
    display: none;
  }

  #rev-SiteFooter .site-logo {
    width: 144px;
    height: 144px;
  }

  #rev-LandingPage .product-section .button-container {
    display: none;
  }

  #rev-ArticleDetailPage .tail {
    display: none;
  }

  #rev-AdminLandingPage {
    & .major-statistic .order {
      display: none;
    }
    & .order-statistic {
      display: none;
    }
  }
`;
