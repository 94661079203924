const TEST_CONSTANTS = {
  test: 'test',
};

const THEME_COLOR = '#03a9f4';
const THEME_COLOR_SECOND = '#67cbf8';

const SITE_INFO = {
  title: '觀霧山莊',
  subtitle: '',
  icon: '/images/favicon.png',
  address: '36545 苗栗縣泰安鄉梅園村觀霧8號',
};

export {TEST_CONSTANTS, SITE_INFO, THEME_COLOR, THEME_COLOR_SECOND};
