import React from 'react';
import styled from 'styled-components';
import {getOutlet} from 'reconnect.js';
import {Button, Result, Space} from 'antd';
import Constants from '../../constants';

const LoginRequired = (options) => {
  const {admin, layout} = options;
  const config = Constants.LOGIN_REQUIRED_CONFIG;

  return (
    <Wrapper admin={admin}>
      <Result
        icon={<img src={config.image} alt="permission-denied" />}
        title={config.title}
        subTitle={config.subtitle}
        extra={
          <Space direction="vertical">
            <Button onClick={() => config.onButtonClick({admin})}>
              {config.buttonText}
            </Button>
          </Space>
        }
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  min-height: ${({admin}) => (admin ? '100vh;' : 'var(--contentMinHeight);')}
  padding-top: var(--topNavBarHeight);
  display: flex;
  align-items: center;
  justify-content: center;
  & img {
    width: 150px
  }
`;

export default LoginRequired;
