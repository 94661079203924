// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-generators-admin-resource-index-js": () => import("./../../../src/Generators/AdminResource/index.js" /* webpackChunkName: "component---src-generators-admin-resource-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-index-js": () => import("./../../../src/Templates/About/index.js" /* webpackChunkName: "component---src-templates-about-index-js" */),
  "component---src-templates-admin-index-js": () => import("./../../../src/Templates/Admin/index.js" /* webpackChunkName: "component---src-templates-admin-index-js" */),
  "component---src-templates-admin-select-cvs-index-js": () => import("./../../../src/Templates/AdminSelectCVS/index.js" /* webpackChunkName: "component---src-templates-admin-select-cvs-index-js" */),
  "component---src-templates-app-proxy-index-js": () => import("./../../../src/Templates/AppProxy/index.js" /* webpackChunkName: "component---src-templates-app-proxy-index-js" */),
  "component---src-templates-article-detail-index-js": () => import("./../../../src/Templates/ArticleDetail/index.js" /* webpackChunkName: "component---src-templates-article-detail-index-js" */),
  "component---src-templates-booking-booking-order-page-js": () => import("./../../../src/Templates/Booking/BookingOrderPage.js" /* webpackChunkName: "component---src-templates-booking-booking-order-page-js" */),
  "component---src-templates-booking-index-js": () => import("./../../../src/Templates/Booking/index.js" /* webpackChunkName: "component---src-templates-booking-index-js" */),
  "component---src-templates-cart-index-js": () => import("./../../../src/Templates/Cart/index.js" /* webpackChunkName: "component---src-templates-cart-index-js" */),
  "component---src-templates-checkout-info-index-js": () => import("./../../../src/Templates/CheckoutInfo/index.js" /* webpackChunkName: "component---src-templates-checkout-info-index-js" */),
  "component---src-templates-checkout-review-index-js": () => import("./../../../src/Templates/CheckoutReview/index.js" /* webpackChunkName: "component---src-templates-checkout-review-index-js" */),
  "component---src-templates-coupon-list-index-js": () => import("./../../../src/Templates/CouponList/index.js" /* webpackChunkName: "component---src-templates-coupon-list-index-js" */),
  "component---src-templates-customer-support-index-js": () => import("./../../../src/Templates/CustomerSupport/index.js" /* webpackChunkName: "component---src-templates-customer-support-index-js" */),
  "component---src-templates-faq-index-js": () => import("./../../../src/Templates/Faq/index.js" /* webpackChunkName: "component---src-templates-faq-index-js" */),
  "component---src-templates-forgot-password-confirm-page-js": () => import("./../../../src/Templates/ForgotPassword/ConfirmPage.js" /* webpackChunkName: "component---src-templates-forgot-password-confirm-page-js" */),
  "component---src-templates-forgot-password-request-page-js": () => import("./../../../src/Templates/ForgotPassword/RequestPage.js" /* webpackChunkName: "component---src-templates-forgot-password-request-page-js" */),
  "component---src-templates-landing-index-js": () => import("./../../../src/Templates/Landing/index.js" /* webpackChunkName: "component---src-templates-landing-index-js" */),
  "component---src-templates-order-list-index-js": () => import("./../../../src/Templates/OrderList/index.js" /* webpackChunkName: "component---src-templates-order-list-index-js" */),
  "component---src-templates-page-preview-index-js": () => import("./../../../src/Templates/PagePreview/index.js" /* webpackChunkName: "component---src-templates-page-preview-index-js" */),
  "component---src-templates-privacy-policy-index-js": () => import("./../../../src/Templates/PrivacyPolicy/index.js" /* webpackChunkName: "component---src-templates-privacy-policy-index-js" */),
  "component---src-templates-product-detail-index-js": () => import("./../../../src/Templates/ProductDetail/index.js" /* webpackChunkName: "component---src-templates-product-detail-index-js" */),
  "component---src-templates-product-list-index-js": () => import("./../../../src/Templates/ProductList/index.js" /* webpackChunkName: "component---src-templates-product-list-index-js" */),
  "component---src-templates-product-stock-index-js": () => import("./../../../src/Templates/ProductStock/index.js" /* webpackChunkName: "component---src-templates-product-stock-index-js" */),
  "component---src-templates-profile-index-js": () => import("./../../../src/Templates/Profile/index.js" /* webpackChunkName: "component---src-templates-profile-index-js" */),
  "component---src-templates-register-confirm-page-js": () => import("./../../../src/Templates/Register/ConfirmPage.js" /* webpackChunkName: "component---src-templates-register-confirm-page-js" */),
  "component---src-templates-register-request-page-js": () => import("./../../../src/Templates/Register/RequestPage.js" /* webpackChunkName: "component---src-templates-register-request-page-js" */),
  "component---src-templates-reset-email-index-js": () => import("./../../../src/Templates/ResetEmail/index.js" /* webpackChunkName: "component---src-templates-reset-email-index-js" */),
  "component---src-templates-service-policy-index-js": () => import("./../../../src/Templates/ServicePolicy/index.js" /* webpackChunkName: "component---src-templates-service-policy-index-js" */),
  "component---src-templates-social-login-index-js": () => import("./../../../src/Templates/SocialLogin/index.js" /* webpackChunkName: "component---src-templates-social-login-index-js" */)
}

