import * as en from 'rev.sdk.js/Locale/en';

const labels = {
  ...en.labels,
  intro: 'Introduction',
  spec: 'Specification',
  remark: 'Notice',
  share_link: 'Share Link',
  loading: 'Loading',
  tab_choose_date: 'DATE',
  tab_choose_room: 'ROOM',
  tab_confirm: 'CHECKOUT',
  date: 'DATE',
  go_back: 'BACK',
  next_step: 'NEXT',
  reset: 'RESET',
  remove: 'X',
  checkin_date: 'Checkin Date',
  checkout_date: 'Checkout Date',
  invalid_date: 'Invalid date',
  room: 'ROOM',
  begin: 'Begin',
  end: 'End',
  select_this_room: 'SELECT THIS ROOM',
  quantity: 'Qty',
  overview: 'Overview',
  adults: 'Adults',
  kids: 'Children',
  contact_info: 'Contact Information',
  your_name: 'Name',
  your_email: 'Email',
  your_phone: 'Mobile Phone',
  other_info: 'Other Information',
  order_note: 'Order Note',
  total: 'Total',
  deposit: 'Deposit',
  room_price: 'Room Price',
  go_to_checkout: 'CHECKOUT',
  booking_notice: 'Booking Notice',
  confirm: 'Confirm',
  not_selected_yet: 'Not selected yet',
  remaining_number: 'Remaining number',
  no_remaining_rooms:
    'Sorry, there is no room that the date you had chosen. Choose again please.',
  booking_info: 'Booking information',
  order_not_found: 'Order is not found',
  agree_booking_notice: 'I agree the booking notice',
  agree_booking_notice_warning: 'Please check the booking notice',
  customer_support: 'Contact',
  customer_support_title: 'About what',
  customer_support_content: 'Detailed description',
  name: 'Name',
  phone: 'Phone',
  email: 'Email',
  expect_arrival_time: 'Estimated time of arrival',
  updated: 'Updated time',
  next_article: 'Next Article',
  prev_article: 'Previous Article',
};

export {labels};
