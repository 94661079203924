import * as tw from 'rev.sdk.js/Locale/tw';

const labels = {
  ...tw.labels,
  intro: '介紹',
  spec: '規格',
  remark: '入住須知',
  share_link: '複製分享連結',
  loading: '載入中',
  tab_choose_date: '選擇時段',
  tab_choose_room: '選擇房間',
  tab_confirm: '確認資料',
  date: '日期',
  go_back: '前一步',
  next_step: '下一步',
  reset: '重設',
  remove: 'X',
  checkin_date: '入住日期',
  checkout_date: '退房日期',
  invalid_date: '不可用的日期',
  room: '房間',
  begin: '開始',
  end: '結束',
  select_this_room: '選擇此房型',
  quantity: '數量',
  overview: '總覽',
  adults: '成人總數',
  kids: '孩童總數',
  contact_info: '聯絡資訊',
  your_name: '您的大名',
  your_email: '您的Email',
  your_phone: '您的手機',
  other_info: '其他資訊',
  order_note: '訂單備註',
  total: '總金額',
  deposit: '訂金',
  room_price: '房間金額',
  go_to_checkout: '前往付款',
  booking_notice: '訂房規範',
  confirm: '確認',
  not_selected_yet: '尚未選擇',
  remaining_number: '剩餘數量',
  no_remaining_rooms:
    '抱歉您選擇的時段，無可預定的房間，請前往上一部重新選擇日期。',
  booking_info: '訂房資訊',
  order_not_found: '找不到訂單',
  agree_booking_notice: '我同意訂房須知條款',
  agree_booking_notice_warning: '請確認同意訂房須知條款',
  customer_support: '聯絡我們',
  customer_support_title: '問題類型',
  customer_support_content: '詢問內容',
  name: '姓名',
  phone: '聯絡電話',
  email: '信箱',
  expect_arrival_time: '預計抵達時間',
  updated: '更新時間',
  next_article: '上一篇',
  prev_article: '下一篇',
};

export {labels};
