import * as ApiUtil from 'rev.sdk.js/Utils/ApiUtil';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import Config from '../../data.json';
const AppActionsOverride = {};
const req = ApiUtil.req;

//////////////////////////////////////////////////
// Override existing AppActions
AppActionsOverride.doCustomThing = async (...args) => {
  console.log(args);
};

AppActionsOverride.sendCustomerSupport = async (values) => {
  await req(`${Config.apiHost}/contact-us/send`, {
    method: 'POST',
    data: {data: values},
  });
  return JStorage.createDocument('customer_support', values, {anonymous: true});
};

export default AppActionsOverride;

//////////////////////////////////////////////////
// Extra custom actions
function sayHello() {
  console.log('hello, this is custom section, custom it');
}

export {sayHello};
